import { Suspense, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { LoadingPage } from "@components/LoadingPage";
import { MainRouter } from "./MainRouter";
import { useTranslate } from "@tolgee/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { message } from "antd";
import { backendLink } from "./env";
import { trpc } from "./trpc";
import {
  httpBatchLink,
  loggerLink,
  splitLink,
  TRPCClientError,
  unstable_httpSubscriptionLink,
} from "@trpc/react-query";
import { getIdToken } from "./utils/getIdToken";
import superjson from "superjson";
import { APIProvider } from "@vis.gl/react-google-maps";

const trpcClient = trpc.createClient({
  links: [
    loggerLink(),
    splitLink({
      condition: (op) => op.type === "subscription",
      true: unstable_httpSubscriptionLink({
        url: backendLink,
        transformer: superjson,
        eventSourceOptions: async () => {
          const token = await getIdToken();
          if (token) {
            return {
              headers: { Authorization: `Bearer ${token}` },
            } as EventSourceInit;
          }

          return {};
        },
      }),
      false: httpBatchLink({
        url: backendLink,
        transformer: superjson,
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: "include",
          });
        },
        headers: async () => {
          const token = await getIdToken();
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          const common = {
            "x-timezone": timezone,
            "x-locale": window.navigator.language,
          };
          return token
            ? { Authorization: `Bearer ${token}`, ...common }
            : { ...common };
        },
      }),
    }),
  ],
});

function App() {
  const { t } = useTranslate("app");
  const queryClient = useMemo(() => {
    return new QueryClient({
      queryCache: new QueryCache({
        onError: (error) => {
          if (error instanceof TRPCClientError) {
            if (error.message === "paypalTokenNotFound") {
              return;
            }

            message.error(t(error.message, error.message));
          } else {
            message.error(t("unknownError", "An unknown error occurred."));
          }
        },
      }),
    });
  }, [t]);

  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
      <QueryClientProvider client={queryClient}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <Suspense fallback={<LoadingPage />}>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <MainRouter />
              </QueryParamProvider>
            </BrowserRouter>
          </Suspense>
        </trpc.Provider>
      </QueryClientProvider>
    </APIProvider>
  );
}

export default App;
