import type { LanguageStorageMiddleware, TolgeePlugin } from "@tolgee/core";

export function isSSR() {
  return typeof globalThis.window?.document?.createElement === "undefined";
}

export function throwIfSSR(origin: string) {
  if (isSSR()) {
    throw new Error(`${origin}: Can't run on the server`);
  }
}

const LANGUAGE_COOKIE_NAME = "__tolgee_language";

function setCookie(name: string, value: string, days: number, domain?: string) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  const domainAttribute = domain ? `; domain=${domain}` : "";
  document.cookie = `${name}=${value}; ${expires}; path=/ ${domainAttribute}`;
}

function getCookie(name: string): string | undefined {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    let c = cookie.trim();
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return undefined;
}

export function createLanguageStorage(): LanguageStorageMiddleware {
  return {
    getLanguage() {
      throwIfSSR("LanguageStorage");
      return getCookie(LANGUAGE_COOKIE_NAME) || undefined;
    },

    setLanguage(language: string) {
      throwIfSSR("LanguageStorage");
      setCookie(LANGUAGE_COOKIE_NAME, language, 365, ".questly.shopping");
    },
  };
}

export const LanguageStorage = (): TolgeePlugin => (tolgee, tools) => {
  tools.setLanguageStorage(createLanguageStorage());
  return tolgee;
};
