import * as Sentry from "@sentry/react";
import { isOnLocalhost } from "./env.ts";
import {
  BackendFetch,
  DevTools,
  LanguageDetector,
  Tolgee,
} from "@tolgee/react";
import { FormatIcu } from "@tolgee/format-icu";
import { MyErrorBoundary } from "@components/MyErrorBoundary.tsx";
import { LanguageStorage } from "./languageStorage.ts";

if (!isOnLocalhost()) {
  Sentry.init({
    dsn: "https://09ba45f5aedb561674af55baee8f2808@o4508242157109248.ingest.de.sentry.io/4508242168840272",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app\.questly\.shopping/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const tolgee = Tolgee()
  .use(LanguageStorage())
  .use(LanguageDetector())
  .use(DevTools())
  .use(
    isOnLocalhost()
      ? undefined
      : BackendFetch({
          prefix: "https://cdn.tolg.ee/bd237d009bb10805b0078eb267cc8737",
        })
  )
  .use(FormatIcu())
  .init({
    availableLanguages: ["en", "hu", "pl"],
    defaultLanguage: "en",
    fallbackLanguage: "en",
    defaultNs: "regionalSettingsDialog",
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    // projectId: 9438,
  });

// @ts-expect-error - This is a fallback render function for the ErrorBoundary.
export function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  // if (error.message.includes("Failed to fetch dynamically imported module")) {
  //   window.location.reload();
  // }

  return (
    <div role="alert" className="flex flex-col grow">
      <MyErrorBoundary error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}
